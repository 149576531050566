<template>
  <div class="p-grid p-justify-center">
    <div class="p-col-12">
      <div class="technic-maker card">
        <div class="p-col-12">
          <TabView>
            <TabPanel header="Модель">
              <ConstructorModelMaker
                :models="technicModels"
                :types="technicTypes"
                :producers="technicProducers"
              />
            </TabPanel>
            <TabPanel header="Тип">
              <ConstructorTypeMaker :types="technicTypes" />
            </TabPanel>
            <TabPanel header="Производитель">
              <ConstructorProducerMaker :producers="technicProducers" />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import ConstructorModelMaker from "./ConstructorModelMaker";
import ConstructorProducerMaker from "./ConstructorProducerMaker";
import ConstructorTypeMaker from "./ConstructorTypeMaker";
export default {
  name: "TechnicMaker",
  components: {
    ConstructorTypeMaker,
    ConstructorProducerMaker,
    ConstructorModelMaker,
  },

  data() {
    return {
      isLoading: false,
      producers: null,
    };
  },
  mounted() {
    this.producers = this.$store.getters["data/technicModels"];
  },
  computed: {
    technicModels() {
      return this.$store.getters["data/technicModels"];
    },
    technicTypes() {
      return this.$store.getters["data/technicTypes"];
    },
    technicProducers() {
      return this.$store.getters["data/technicProducers"];
    },
  },
};
</script>

<style lang="scss">
.technic-maker {
  .p-inputtext {
    min-width: 70px;
  }
}
</style>
