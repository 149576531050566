<template>
  <div class="p-grid p-d-flex menu p-ml-auto p-mt-auto">
    <InputText
      v-model="newModelName"
      class="p-mr-2 p-mb-2 p-mb-md-2 p-mb-sm-2 p-lg-3 p-md-12 p-sm-12 p-xs-12"
      placeholder="Название модели"
    />
    <Dropdown
      id="type"
      v-model="newType"
      :filter="true"
      :options="types"
      :required="true"
      optionLabel="name"
      placeholder="Тип"
      :disabled="isLoading"
      class="p-mr-2 p-mb-2 p-mb-md-2 p-mb-sm-2 p-lg-3 p-md-12 p-sm-12 p-xs-12"
      emptyFilterMessage="По вашему запросу типов не найдено"
    />
    <Dropdown
      id="producer"
      v-model="newProducer"
      :filter="true"
      :options="producers"
      :required="true"
      optionLabel="name"
      placeholder="Производитель"
      :disabled="isLoading"
      class="p-mb-md-2 p-mb-sm-2 p-lg-3 p-md-12 p-sm-12 p-xs-12 p-mb-2"
      emptyFilterMessage="По вашему запросу производителей не найдено"
    />
    <div class="p-ml-auto p-mr-2" v-if="!editMode">
      <Button
        :disabled="!newModelName || !newType || !newProducer"
        @click="createNewModel"
        label="Создать"
      />
    </div>
    <div class="p-d-flex p-ml-auto p-mr-lg-2 p-mb-md-2 p-mb-sm-2" v-else>
      <Button
        :disabled="!newModelName"
        class="p-mr-2"
        @click="editModel"
        label="Обновить"
      />
      <Button
        class="p-button-secondary"
        @click="disableEditMode"
        label="Отменить"
      />
    </div>
  </div>
  <div class="p-mt-3 p-field">
    <DataTable
      :value="modelsWithFilters"
      @row-click="goToStandardHours"
      class="p-datatable-customers p-datatable-striped"
      selection-mode="single"
      dataKey="id"
      :rows="10"
      :rowHover="true"
      :autoLayout="true"
      v-model:filters="filters"
      filterDisplay="row"
      stateStorage="session"
      stateKey="constructor-model-filter"
      responsive-layout="scroll"
      :loading="isLoading"
    >
      <template #header>
        <div class="p-d-flex p-jc-end">
          <Button class="p-button-danger" @click="clearFilters"
            >Очистить фильтры</Button
          >
        </div>
      </template>
      <template #empty>Список моделей пуст</template>
      <template #loading>Загрузка...</template>
      <Column
        field="name"
        header="Название"
        :sortable="true"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="``"
            v-tooltip.top.focus="''"
          />
        </template>
      </Column>
      <Column
        field="type"
        header="Тип"
        :sortable="true"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          {{ slotProps.data.type }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="``"
            v-tooltip.top.focus="''"
          />
        </template>
      </Column>
      <Column
        field="producer"
        header="Производитель"
        :sortable="true"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          {{ slotProps.data.producer }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="``"
            v-tooltip.top.focus="''"
          />
        </template>
      </Column>
      <Column
        field="visibility"
        header="Скрытый"
        :sortable="true"
        bodyStyle="text-align: center"
        headerStyle="display:flex; justify-content:center"
      >
        <template #body="slotProps">
          <Checkbox
            @change="confirmHiding(slotProps.data.model)"
            v-model="slotProps.data.model.block"
            :binary="true"
          />
        </template>
      </Column>
      <Column
        field="actions"
        header="Действия"
        bodyStyle="text-align: center"
        headerStyle="text-align: center"
      >
        <template #body="slotProps">
          <Button
            class="p-button-warning p-mr-3"
            icon="pi pi-pencil"
            @click="enableEditMode(slotProps.data.model)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { environment } from "../../config";
import { FilterMatchMode } from "primevue/api";

export default {
  props: ["models", "types", "producers"],
  name: "ConstructorModelMaker",
  data() {
    return {
      newModelName: null,
      isLoading: false,
      newProducer: null,
      newType: null,
      editMode: false,
      modelForEdit: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        producer: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    modelsWithFilters() {
      return this.models.map((p) => {
        return {
          model: p,
          name: p.name,
          type: p.technicType.name,
          producer: p.technicProducer.name,
        };
      });
    },
  },
  methods: {
    goToStandardHours(rowData) {
      this.$router.push(`/technic_maker/${rowData.data.model.uid}`);
    },
    clearFilters() {
      for (const key in this.filters) {
        this.filters[key].value = "";
      }
      sessionStorage.removeItem("constructor-model-filter");
      const inputs = document.getElementsByClassName(
        "p-inputtext p-component p-column-filter"
      );
      const event = new Event("input");
      for (const input of inputs) {
        input.value = "";
        input.dispatchEvent(event);
      }
    },
    disableEditMode() {
      this.editMode = false;
      this.newModelName = null;
    },
    confirmHiding(slotProps) {
      slotProps.block = !slotProps.block;
      let message = "Вы действительно хотите заблокировать модель?";
      if (slotProps.block === true) {
        message = "Вы действительно хотите разблокировать модель?";
      }
      this.$confirm.require({
        message: message,
        key: "producerDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.hideModel(slotProps);
        },
      });
    },
    enableEditMode(data) {
      this.editMode = true;
      this.newModelName = data.name;
      this.newType = data.technicType;
      this.newProducer = data.technicProducer;
      this.modelForEdit = data;
    },
    async editModel() {
      this.isLoading = true;
      try {
        const actionPayload = {
          name: this.newModelName,
          technicType: this.newType,
          technicProducer: this.newProducer,
          uid: this.modelForEdit.uid,
        };
        const response = await fetch(
          `${environment.apiUrl}/technic_models/${this.modelForEdit.uid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(actionPayload),
          }
        );
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.editMode = false;
        this.newModelName = null;
        this.newProducer = null;
        this.newType = null;
        this.$store.dispatch("data/loadTechnicModels");
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка!",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async hideModel(data) {
      this.isLoading = true;
      console.log(data);
      data.block = !data.block;
      try {
        const response = await fetch(
          `${environment.apiUrl}/technic_models/${data.uid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(data),
          }
        );
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.$store.dispatch("data/loadTechnicModels");
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка!",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async createNewModel() {
      this.isLoading = true;
      try {
        const actionPayload = {
          name: this.newModelName,
          technicProducer: this.newProducer,
          technicType: this.newType,
        };
        const response = await fetch(`${environment.apiUrl}/technic_models`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(actionPayload),
        });
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.$store.dispatch("data/loadTechnicModels");
        this.newModelName = null;
        this.newProducer = null;
        this.newType = null;
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка!",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.p-dropdown {
  padding: 0;
}
</style>
