<template>
  <div class="p-d-flex p-justify-between">
    <InputText
      v-model="newProducerName"
      style="width: 100%"
      class="p-mr-2"
      placeholder="Название производителя"
    />
    <div v-if="!editMode">
      <Button
        :disabled="!newProducerName"
        @click="createNewProducer"
        label="Создать"
      />
    </div>
    <div class="p-d-flex row" v-else>
      <Button class="p-mr-2" @click="editProducer" label="Обновить" />
      <Button
        class="p-button-secondary"
        @click="disableEditMode"
        label="Отменить"
      />
    </div>
  </div>
  <div class="p-mt-3 p-field">
    <DataTable
      :value="producers"
      class="p-datatable-customers p-datatable-striped"
      dataKey="id"
      :rows="10"
      :rowHover="true"
      :autoLayout="true"
      v-model:filters="filters"
      filterDisplay="row"
      stateStorage="session"
      stateKey="constructor-producer-filter"
      responsive-layout="scroll"
      :loading="isLoading"
    >
      <template #header>
        <div class="p-d-flex p-jc-end">
          <Button class="p-button-danger" @click="clearFilters"
            >Очистить фильтры</Button
          >
        </div>
      </template>
      <template #empty>Список производителей пуст</template>
      <template #loading>Загрузка...</template>
      <Column
        field="name"
        header="Название"
        :sortable="true"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @input="filterCallback()"
            class="p-column-filter"
            :placeholder="``"
            v-tooltip.top.focus="''"
          />
        </template>
      </Column>
      <Column
        field="visibility"
        header="Скрытый"
        :sortable="true"
        bodyStyle="text-align: center"
        headerStyle="display:flex; justify-content:center"
      >
        <template #body="slotProps">
          <Checkbox
            @change="confirmHiding(slotProps.data)"
            v-model="slotProps.data.block"
            :binary="true"
          />
        </template>
      </Column>
      <Column
        field="actions"
        header="Действия"
        bodyStyle="text-align: center"
        headerStyle="text-align: center"
      >
        <template #body="slotProps">
          <Button
            class="p-button-warning p-mr-3"
            icon="pi pi-pencil"
            @click="enableEditMode(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
  <ConfirmDialog group="producerDialog"></ConfirmDialog>
</template>

<script>
import { environment } from "../../config";
import { FilterMatchMode } from "primevue/api";

export default {
  props: ["producers"],
  name: "ConstructorProducerMaker",
  data() {
    return {
      isLoading: false,
      newProducerName: null,
      editMode: false,
      producerForEdit: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  methods: {
    clearFilters() {
      for (const key in this.filters) {
        this.filters[key].value = "";
      }
      sessionStorage.removeItem("constructor-producer-filter");
      const inputs = document.getElementsByClassName(
        "p-inputtext p-component p-column-filter"
      );
      const event = new Event("input");
      for (const input of inputs) {
        input.value = "";
        input.dispatchEvent(event);
      }
    },
    disableEditMode() {
      this.editMode = false;
      this.newProducerName = null;
    },
    confirmHiding(slotProps) {
      slotProps.block = !slotProps.block;
      let message = "Вы действительно хотите заблокировать производителя?";
      if (slotProps.block === true) {
        message = "Вы действительно хотите разблокировать производителя?";
      }
      this.$confirm.require({
        message: message,
        key: "producerDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.hideProducer(slotProps);
        },
      });
    },
    enableEditMode(data) {
      this.editMode = true;
      this.newProducerName = data.name;
      this.producerForEdit = data;
    },
    async editProducer() {
      this.isLoading = true;
      try {
        const actionPayload = this.producerForEdit;
        actionPayload.name = this.newProducerName;
        const response = await fetch(
          `${environment.apiUrl}/technic_producers/${this.producerForEdit.uid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(actionPayload),
          }
        );
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.editMode = false;
        this.newProducerName = null;
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка!",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async hideProducer(data) {
      this.isLoading = true;
      data.block = !data.block;

      try {
        const response = await fetch(
          `${environment.apiUrl}/technic_producers/${data.uid}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(data),
          }
        );
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.$store.dispatch("data/loadTechnicProducers");
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка!",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
    async createNewProducer() {
      this.isLoading = true;
      try {
        const actionPayload = {
          name: this.newProducerName,
        };
        const response = await fetch(
          `${environment.apiUrl}/technic_producers`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(actionPayload),
          }
        );
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.$store.dispatch("data/loadTechnicProducers");
        this.newProducerName = null;
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка!",
          detail: err,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
